import DickDeer from './DickDeer.png';
import './App.css';
import { useEffect, useState } from "react";
import Snowfall from 'react-snowfall'
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import ReactGA from 'react-ga';

ReactGA.initialize('G-ZPMJDENZTW');
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  const [cursorPosition, setCursorPosition] = useState({ top: '50vh', left: '50vw' });
  const [isMouseControlled, setIsMouseControlled] = useState(false);


  const onMouseMove = (e) => {
    setCursorPosition({ top: e.pageY+5, left: e.pageX+5 });
    setIsMouseControlled(true);
  }

  const trackClick = (e) => {
    ReactGA.event({
      category: 'Click',
      action: 'Clicked on link',
      label: e.target.href
    });
  }

  useEffect(() => {
    let interval = null;
    if (!isMouseControlled) {
      interval = setInterval(() => {
        setCursorPosition({ top: Math.random() * 75 + 'vh', left: Math.random() * 75 + 'vw' });
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isMouseControlled]);

  return (
    <div className="App" onMouseMove={onMouseMove} >
      <Snowfall />
      
      <div>
      <Link onClick={trackClick} href="https://www.zazzle.com/dick_deer_bottle_koozie-256287908678307277"><Button variant="contained">Buy Bottle Koozies Now!</Button></Link>
      <br/>
      <Link onClick={trackClick} href="https://www.zazzle.com/dick_deer_koozie-256276096304605051"><Button variant="contained">Buy Can Koozies Now!</Button></Link>
      <br/>
      <Link onClick={trackClick} href="https://dickdeer.creator-spring.com/"><Button variant="contained">Buy Everything Else Now!</Button></Link>
      </div>

      {/* <div>
      <Link href="#"><Button variant="contained">Buy Bottle Koozies Now! (COMING SOON!)</Button></Link>
      <br/>
      <Link href="#"><Button variant="contained">Buy Can Koozies Now! (COMING SOON!)</Button></Link>
      <br/>
      <Link href="#"><Button variant="contained">Buy Everything Else Now! (COMING SOON!)</Button></Link>
      </div> */}
      
      <div style={{position: 'absolute', ...cursorPosition}}>
        <img src={DickDeer} className="DickDeer" alt="logo"/>
      </div> 
    </div>
  );
}

export default App;
